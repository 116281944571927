import React, { useState, useEffect } from 'react';

const formatNumber = (num) => {
    const absNum = Math.abs(num);
    if (absNum >= 1e9) {
        return (num / 1e9).toFixed(1) + 'B';
    } else if (absNum >= 1e6) {
        return (num / 1e6).toFixed(1) + 'M';
    } else if (absNum >= 1e3) {
        return (num / 1e3).toFixed(1) + 'K';
    }
    return num.toLocaleString();
};

const TotalQuizzesPlayed = () => {
    const [quizzesPlayed, setQuizzesPlayed] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('https://cruncher.asset.money/artist/user-count-all-quizzes')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setQuizzesPlayed(data.data);
                setIsLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoading(false);
            });
    }, []);

    return (
        <div className="card">
            <h2 className="text-xl font-bold mb-4">Total Quizzes Played</h2>
            {isLoading ? (
                <div className="number loading">Loading...</div>
            ) : error ? (
                <div className="number error">Error: {error}</div>
            ) : (
                <div className="number">{quizzesPlayed?.toLocaleString()}</div>
            )}
        </div>
    );
};

export default TotalQuizzesPlayed;