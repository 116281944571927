import React, { useEffect, useState } from 'react';

const TotalCumulativeUsersMonthly = () => {
    const [data, setData] = useState(null);
    const [cumulativeUsers, setCumulativeUsers] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // fetch('https://cruncher.asset.money/artist/user-count-for-this-month')
        fetch('https://cruncher.asset.money/artist/user-count-past-three-months')
            .then(response => response.json())
            .then(data => {
                setData(data.data);
                setIsLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        // curl --location 'https://cruncher.asset.money/artist/cumulative-users'
        fetch('https://cruncher.asset.money/artist/cumulative-users')
            .then(response => response.json())
            .then(data => {
                setCumulativeUsers(data.data?.cumulative_users);
            })
    }, [])


    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const getLastMonthName = () => {
        const date = new Date();
        date.setMonth(date.getMonth() - 1);
        return date.toLocaleString('default', { month: 'long' });
    };
    const getTwoMonthsBackName = () => {
        const date = new Date();
        date.setMonth(date.getMonth() - 2);
        return date.toLocaleString('default', { month: 'long' });
    };

    return (
       <div className='flex flex-col justify-center items-center gap-4 w-full'>
         <div className="card w-full">
            <h2 className="text-xl font-bold mb-4">Cumulative active users</h2>
            {isLoading ? (
                <div className="number loading">Loading...</div>
            ) : error ? (
                <div className="number error">Error: {error}</div>
            ) : (
                <div className="number">{cumulativeUsers?.toLocaleString()}</div>
            )}
        </div>
        <div className="card w-full">
            <h2 className="text-xl font-bold mb-4">MAU - {getLastMonthName()}</h2>
            {isLoading ? (
                <div className="number loading">Loading...</div>
            ) : error ? (
                <div className="number error">Error: {error}</div>
            ) : (
                <div className="number">{data[1]?.cumulative_users?.toLocaleString()}</div>
            )}
        </div>
       </div>
    );
};

export default TotalCumulativeUsersMonthly;